import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, throwError  } from 'rxjs';
import { timeout, catchError, map, finalize, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

import { TokenService } from '../token/token.service';
import { TranslateService } from '@ngx-translate/core';

import { environment as env } from 'src/environments/environment';
import { of } from 'rxjs/internal/observable/of';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import {
  NotificationSeverity,
  NotifyService
} from '../notification/notify.service';
import { UserProfile } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public displayLogin: boolean;
  private userLoggedIn = new Subject<boolean>();
  private userProfileModel: UserProfile | null;

  constructor(
    private http: HttpClient,
    private token: TokenService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private notification: NotifyService,
    private translate: TranslateService,
  ) {
    this.displayLogin = false;
    this.userLoggedIn.next(false);
    this.userProfileModel = {
      AcctId: 0,
      IpAddress: '',
      AcctName: '',
      AcctUserId: ''
    };
  }

  public login(
    username: string,
    password: string,
    captcha: string
  ): Observable<boolean> {
    return this.postLogin(username, password, captcha).pipe(
      map((t) => {
        if (t == undefined) {
          return false;
        }

        if (t.Model && t.Model?.Profile) {
          this.clearToken();

          if(this.userProfileModel){
            this.userProfileModel.AcctId = t.Model.Profile.AcctId;
            this.userProfileModel.IpAddress = t.Model.Profile.LastLoginIp;
            this.userProfileModel.AcctName = t.Model.Profile.AcctName;
            this.userProfileModel.AcctUserId = t.Model.Profile.AcctUserId;

            const userProfileJson = JSON.stringify(this.userProfileModel);
            this.token.setLocalToken(env.TokenKeys.Profile, userProfileJson);
          }

          this.token.setLocalToken(env.TokenKeys.AccessToken, t.Model?.Token);
          this.userLoggedIn.next(true);
          return true;
        } else {
          // Temporary
          this.handleApiError(t);
          return false;
        }
      })
    );
  }

  public logout(): Observable<boolean> {
    const userProfile = this.getUserProfile();
    const acctUserId = userProfile?.AcctUserId ?? 0;

    const payload = {
      CurrentCulture: 'en-US',
      AcctUserId: acctUserId,
      UserRoleId: 0,
      LoggedInAcctUserId: acctUserId,
      IpAddress: null,
      UserAgent: null,
      Country: null,
    };

    return this.http.post<any>(`${env.HttpConfig.EndPoint}/api/account/logoutaccount`, payload).pipe(
      tap(response => {
        if(response.Code && response.Code == 1){
          this.clearToken();
        } else {    
          this.displayLogoutError();
        }
      }),
      catchError(error => {
        this.displayLogoutError();
        return throwError(error);
      })
    );
  }

  displayLogoutError(){
    var logout_error_title = "";
      var logout_error_msg = "";
      this.translate
      .get('notifications.title.error')
      .subscribe((text: string) => {
        logout_error_title = text;
      });

      this.translate
      .get('notifications.something_went_wrong.message')
      .subscribe((text: string) => {
        logout_error_msg = text;
      });

      this.notification.displayNotification(
        logout_error_title,
        logout_error_msg,
        NotificationSeverity.Error
      );
  }

  public refreshToken(): Observable<any> {
    const refreshToken = this.token.getLocalToken(env.TokenKeys.AccessToken) ?? "";
    // const userProfile = this.getUserProfile();

    const payload = {
      CurrentCulture: "en-US",
      // AcctUserId: userProfile?.AcctUserId,
      UserRoleId: 0,
      // LoggedInAcctUserId: userProfile?.AcctUserId,
      // IpAddress: userProfile?.IpAddress,
      UserAgent: null,
      Country: null,
      RefreshToken: refreshToken
    }

    return this.http.post<any>(`${env.HttpConfig.EndPoint}/api/account/refreshtoken`, payload).pipe(
      tap(response => {
        if(response.Code && response.Code == 1){
          this.token.removeLocalToken(env.TokenKeys.AccessToken);
          this.token.setLocalToken(env.TokenKeys.AccessToken, response.Token);
        } else {
          var session_expired_title = "";
          var session_expired_msg = "";
          this.translate
          .get('notifications.title.error')
          .subscribe((text: string) => {
            session_expired_title = text;
          });

          this.translate
          .get('notifications.session_expired.message')
          .subscribe((text: string) => {
            session_expired_msg = text;
          });

          this.notification.displayNotification(
            session_expired_title,
            session_expired_msg,
            NotificationSeverity.Error
          );
          this.clearToken();
        }
      }),
      catchError(error => {
        this.logout();
        return throwError(error);
      })
    );
  }

  public clearToken(){
    this.token.removeLocalToken(env.TokenKeys.AccessToken);
    this.token.removeLocalToken(env.TokenKeys.Profile);
  }

  public isAuthenticated(): boolean {
    const token: string | null = this.token.getLocalToken(
      env.TokenKeys.AccessToken
    );

    return token ? true : false;
  }

  public getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  public toggleLoginDisplay(state: boolean) {
    this.displayLogin = state;
  }

  public getAcctName(): string {
    const userProfile = this.getUserProfile();
    return userProfile?.AcctName || '';
  }

  public getUserProfile() :any {
    const userProfileJson = this.token.getLocalToken(env.TokenKeys.Profile);
    let userProfile = null;
    if (userProfileJson) {
      userProfile = JSON.parse(userProfileJson);
    }
    return userProfile;
  }

  private postLogin(
    username: string,
    password: string,
    captcha: string
  ): Observable<any> {
    const body = {
      Username: username,
      Password: password != null ? this.createHash256(password) : '',
      IsCaptchaSuccess: this.validateCaptcha(captcha),
      Platforms: [3],
      CurrentCulture: 'en-US',
      AcctUserId: null,
      UserRoleId: 0,
      LoggedInAcctUserId: null,
      IpAddress: null,
      UserAgent: null,
      Country: null
    };

    const httpHeaders: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const endPoint: string = `${env.HttpConfig.EndPoint}/api/account/login`;
    const options = { headers: httpHeaders };

    if (!env.production) {
      console.log('http post endpoint:', endPoint);
      console.log('http post body:', body);
    }

    this.spinner.show();
    return this.http.post(endPoint, body, options).pipe(
      map((m) => {
        if (!env.production) {
          console.log('api return:', m);
        }
        return m;
      }),
      timeout(env.HttpConfig.Timeout),
      catchError(this.handleError(body)),
      finalize(() => this.spinner.hide())
    );
  }

  private validateCaptcha(captcha: string): boolean {
    return true;
  }

  // Temporary
  private handleApiError(t: any) {
    console.log('E==>', t);
    if (t != null) {
      switch (t.Code) {
        case 3:
          let invalid_grant_title: string = '';
          let invalid_grant_message: string = '';
          this.translate
            .get('notifications.login.invalid_grant.title')
            .subscribe((text: string) => {
              invalid_grant_title = text;
            });
          // this.translate
          //   .get('notifications.login.invalid_grant.message')
          //   .subscribe((text: string) => {
          //     invalid_grant_message = text;
          //   });
          invalid_grant_message = t.Message;
          this.notification.displayNotification(
            invalid_grant_title,
            invalid_grant_message,
            NotificationSeverity.Error
          );
          break;
        default:
          break;
      }
    }
  }

  public createHash256(inputData: string): string {
    // Create a SHA256 hash
    const hash = CryptoJS.SHA256(inputData);

    // Convert hash to a hex string
    const hexString = hash.toString(CryptoJS.enc.Hex);

    return hexString;
  }

  private handleError<T>(result?: T) {
    return (err: any): Observable<T> => {
      if (!env.production) {
        console.log('http error:', err);
      }
      switch (err.error.error) {
        case 'invalid_grant':
          let invalid_grant_title: string = '';
          let invalid_grant_message: string = '';
          this.translate
            .get('notifications.login.invalid_grant.title')
            .subscribe((text: string) => {
              invalid_grant_title = text;
            });
          this.translate
            .get('notifications.login.invalid_grant.message')
            .subscribe((text: string) => {
              invalid_grant_message = text;
            });

          break;
        case 'reset_required':
          this.token.removeLocalToken(env.TokenKeys.UsernameToken);
          this.token.setLocalToken(
            env.TokenKeys.UsernameToken,
            err.error.error_description
          );

          this.router.navigate(['']);
          break;
        default:
          break;
      }
      return of(result as T);
    };
  }
}
