import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private changePasswordDialogSubject = new BehaviorSubject<boolean>(false);
  changePasswordDialogState$ = this.changePasswordDialogSubject.asObservable();

  private reportDialogSubject = new BehaviorSubject<boolean>(false);
  reportDialogState$ = this.reportDialogSubject.asObservable();

  openChangePasswordDialog() {
    this.changePasswordDialogSubject.next(true);
  }

  closeChangePasswordDialog() {
    this.changePasswordDialogSubject.next(false);
  }

  openReportDialog() {
    this.reportDialogSubject.next(true);
  }

  closeReportDialog() {
    this.reportDialogSubject.next(false);
  }
}
