import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs/internal/Subscription';

import { RunningMessage } from 'src/app/models';
import { TokenService } from 'src/app/services';

import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'cm-running-message',
  templateUrl: './running-message.component.html',
  styleUrls: ['./running-message.component.css']
})
export class RunningMessageComponent implements OnInit, OnDestroy {
  @Input() runningMessages: RunningMessage[];
  protected selectedLanguage: string | null;
  private language$: Subscription | null;

  constructor(private token: TokenService, private translate: TranslateService) {
    this.runningMessages = [];
    this.selectedLanguage = '';
    this.language$ = null;
  }

  ngOnInit() {
    this.selectedLanguage = this.token.getLocalToken(env.TokenKeys.LanguageToken);
    console.log(this.selectedLanguage);

    if(!this.selectedLanguage) {
      this.selectedLanguage = "en-US"
    } 

    this.language$ = this.translate.onLangChange.subscribe((value: any) => {
      this.selectedLanguage = value.lang;
    });
  }

  ngOnDestroy(): void {
    this.language$?.unsubscribe();
  }
}
