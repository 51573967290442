import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreenSizeService {
  private screenWidth = new BehaviorSubject<number>(window.innerWidth);
  private screenHeight = new BehaviorSubject<number>(window.innerHeight);

  constructor(private ngZone: NgZone) {
    // Listen to window resize events
    window.addEventListener('resize', this.onResize.bind(this));
  }

  private onResize() {
    this.ngZone.run(() => {
      this.screenWidth.next(window.innerWidth);
      this.screenHeight.next(window.innerHeight);
    });
  }

  getScreenWidth() {
    return this.screenWidth.asObservable();
  }

  getScreenHeight() {
    return this.screenHeight.asObservable();
  }
}
