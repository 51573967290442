import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment as env } from '../../../../environments/environment';
import {
  AuthService,
  TokenService,
  ScreenSizeService,
  DialogService,
  CreditService
} from 'src/app/services';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Discover, UserInfo } from 'src/app/models';
import { LandingPageService } from '../../pages/landing-page/landing-page.service';

@Component({
  selector: 'cm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  protected displayLanguageSelection: boolean;
  protected currentLanguage: string;

  protected discover: Discover | null;
  protected userInfo: UserInfo | null;
  private userInfo$: Subscription | null;
  screenWidth: number;
  private screenWidthSubscription$: Subscription | null;
  protected creditBalance: number;
  protected displayReport: boolean = false;

  constructor(
    protected auth: AuthService,
    private token: TokenService,
    private translate: TranslateService,
    private landingPageService: LandingPageService,
    private screenSizeService: ScreenSizeService,
    private dialogService: DialogService,
    private creditService: CreditService
  ) {
    this.displayLanguageSelection = false;
    this.currentLanguage = 'en-US';
    this.discover = null;
    this.userInfo = null;
    this.userInfo$ = null;
    this.screenWidth = 0;
    this.screenWidthSubscription$ = null;
    this.creditBalance = 0.0;
  }

  ngOnInit(): void {
    const language = this.token.getLocalToken(env.TokenKeys.LanguageToken);
    this.currentLanguage = language ? language : 'en-US';

    this.landingPageService.getDiscover().subscribe((resp: Discover) => {
      this.discover = resp;
      //Testing
      if (!resp) {
        this.discover = {
          Code: 0,
          Message: '',
          Id: '',
          Username: '',
          Name: '',
          Currency: '',
          Cash: 0,
          Chip: 0,
          Point: 0,
          Banners: [],
          RunningMessages: [],
          GameCategories: [],
          Games: [],
          Wallets: [],
          InGameId: ''
        };
        console.log('Discover00==>', this.discover);
        this.discover.RunningMessages = [
          {
            Id: '',
            Message: 'BIG855 Online Gambling is wonderful only with you BIG855 opens the door to wealth',
            MessageCn: 'BIG855 网上博彩 有你才精彩 BIG855 打开富贵门',
            MessageTh: 'การพนันออนไลน์ BIG855 วิเศษสำหรับคุณเท่านั้น BIG855 เปิดประตูสู่ความมั่งคั่ง',
            MessageVn: 'Cờ bạc trực tuyến BIG855 chỉ tuyệt vời khi có bạn BIG855 mở ra cánh cửa giàu có',
            MessageId: 'Perjudian Online BIG855 luar biasa hanya dengan Anda BIG855 membuka pintu menuju kekayaan',
            MessageKo: 'BIG855 온라인 도박은 당신과 함께 훌륭합니다 BIG855가 부의 문을 엽니다',
            MessageTw: 'BIG855 网上博彩 有你才精彩 BIG855 打开富贵门',
            MessageJp: 'BIG855 オンラインギャンブルはあなただけで素晴らしい BIG855 は富への扉を開きます'
          }
        ];
      }
    });
    this.subscribeToUserInfoChange();

    this.screenWidthSubscription$ = this.screenSizeService
      .getScreenWidth()
      .subscribe((width) => {
        this.screenWidth = width;
      });

    if (this.auth.isAuthenticated()) {
      this.refreshBalance();
    }

    this.creditService.creditBalance$.subscribe((balance) => {
      this.creditBalance = balance;
    });
  }

  ngOnDestroy(): void {
    this.userInfo$?.unsubscribe();
  }

  protected onLanguageClick(lang: string): void {
    this.translate.use(lang);
    this.token.removeLocalToken(env.TokenKeys.LanguageToken);
    this.token.setLocalToken(env.TokenKeys.LanguageToken, lang);
    this.currentLanguage = lang;
    this.displayLanguageSelection = false;
  }

  protected handleLangBox(): void {
    this.displayLanguageSelection = !this.displayLanguageSelection;
  }

  private subscribeToUserInfoChange(): void {
    this.userInfo$ = this.landingPageService.userInfoSubject$.subscribe(
      (ui) => {
        this.userInfo = ui;
      }
    );
  }

  protected refreshBalance() {
    this.creditService.refreshBalance();
  }

  showChangePasswordDialog(): void {
    this.dialogService.openChangePasswordDialog();
  }

  showReportDialog(): void {
    this.dialogService.openReportDialog();
  }

  logout() {
    this.auth.logout().subscribe(
      (success) => {
        if (success) {
          console.log('Successfully logged out');
        } else {
          console.error('Logout failed');
        }
      },
      (error) => {
        console.error('Logout error', error);
      }
    );
  }
}
