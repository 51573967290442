<header id="main-header">
  <div class="align-self-center flex-grow-1">
    <div class="d-flex">
      <div class="align-self-center">
        <a class="logo-wrapper" routerLink="/contents/pages/landing-page">
          <img alt="logo" class="header-logo" />
        </a>
      </div>
      <div class="align-self-center">
        <cm-running-message
          *ngIf="discover && this.screenWidth > 768"
          [runningMessages]="discover.RunningMessages"
          class="running-message-wrapper"
        ></cm-running-message>
      </div>
    </div>
  </div>

  <div class="align-self-center">
    <div class="header-top">
      <div
        *ngIf="this.screenWidth > 768"
        class="login-before"
        [ngClass]="{
          '': auth.isAuthenticated(),
          show: !auth.isAuthenticated()
        }"
      >
        <a
          class="btn-top login-btn me-3"
          tabindex="4"
          (click)="auth.toggleLoginDisplay(true)"
        >
          <span class="i18n" data-tag="login">{{
            'accounts.login.login' | translate
          }}</span>
        </a>
      </div>

      <div
        *ngIf="this.screenWidth > 768"
        class="logined login-after"
        [ngClass]="{
          show: auth.isAuthenticated(),
          '': !auth.isAuthenticated()
        }"
      >
        <div class="wallet wallet-user">
          <img src="../../../assets/images/header/login-icon-user.png" />
          <div>
            <a class="account" href="javascript:void(0)"> {{ auth.getAcctName() }} </a>
            <p style="margin-bottom: 0 !important;">
              <span class="money">{{ creditBalance | decimalFormat: 2 }}</span>
              <br />
              <span class="fa fa-refresh" (click)="refreshBalance()"></span>
            </p>
          </div>
        </div>

        <div class="wallet" (click)="showReportDialog()">
          <img src="../../../assets/images/header/login-icon-report.png" />
          <div>
            <span class="i18n" data-tag="report">
              {{ 'contents.log-info.report' | translate }}
            </span>
          </div>
        </div>

        <div class="wallet" (click)="showChangePasswordDialog()">
          <img src="../../../assets/images/header/login-icon-password.png" />
          <div>
            <span class="i18n" data-tag="changePassword">
              {{ 'contents.log-info.password' | translate }}
            </span>
          </div>
        </div>

        <div class="wallet" (click)="logout()">
          <img src="../../../assets/images/header/login-icon-logout.png" />
          <div>
            <span class="i18n" data-tag="logout">
              {{ 'contents.log-info.logout' | translate }}
            </span>
          </div>
        </div>
      </div>
      <div>
        <img
          style="cursor: pointer"
          class="current-language"
          src="../../assets/images/header/{{ currentLanguage }}.png"
          (click)="handleLangBox()"
        />
        <span
          class="langBox"
          [ngStyle]="
            displayLanguageSelection ? { display: 'flex' } : { display: 'none' }
          "
        >
          <a class="lang" (click)="onLanguageClick('zh-CN')">
            <img src="../../assets/images/header/zh-CN.png" />
          </a>
          <a class="lang" (click)="onLanguageClick('en-US')">
            <img src="../../assets/images/header/en-US.png" />
          </a>
          <a class="lang" (click)="onLanguageClick('id-ID')">
            <img src="../../assets/images/header/id-ID.png" />
          </a>
          <a class="lang" (click)="onLanguageClick('th-TH')">
            <img src="../../assets/images/header/th-TH.png" />
          </a>
          <a class="lang" (click)="onLanguageClick('vi-VN')">
            <img src="../../assets/images/header/vi-VN.png" />
          </a>
          <a class="lang" (click)="onLanguageClick('tw-TW')">
            <img src="../../assets/images/header/tw-TW.png" />
          </a>
          <a class="lang" (click)="onLanguageClick('jp-JP')">
            <img src="../../assets/images/header/jp-JP.png" />
          </a>
          <a class="lang" (click)="onLanguageClick('ko-KO')">
            <img src="../../assets/images/header/ko-KO.png" />
          </a>
        </span>
      </div>
    </div>
  </div>
</header>

<!-- <ng-container *ngIf="this.screenWidth > 768">
  <p-dialog class="non-header" [(visible)]="displayChangePassword" [dismissableMask]="true" [draggable]="false"
    [resizable]="false" [style]="{width: '400px'}">
    <div class="align-item-center">
      <cm-change-password (close)="onCloseChangePasswordDialog()"></cm-change-password>
    </div>
  </p-dialog>

  <cm-report [visible]="displayReport" (close)="onCloseReportDialog()">
  </cm-report>
</ng-container> -->


<!-- 
<p-dialog
    header="{{ 'dialog-box-header.language' | translate }}"
    [(visible)]="displayLanguageSelection"
    [dismissableMask]="true"
    [draggable]="false"
    [resizable]="false"
    [style]="{ width: '300px' }"
>
    <div class="d-flex align-item-center">
        <div
            type="button"
            href="#"
            (click)="onLanguageClick('en')"
            class="w-100 language-text d-flex flex-column align-items-center"
        >
            <img
                src="../../assets/images/header/lang-en.png"
                alt="en"
                class="language-img"
            />
            {{ 'accounts.login.english' | translate }}
        </div>
        <hr />
        <div
            type="button"
            href="#"
            (click)="onLanguageClick('cn')"
            class="w-100 language-text d-flex flex-column align-items-center"
        >
            <img
                src="../../assets/images/header/lang-cn.png"
                alt="cn"
                class="language-img"
            />
            {{ 'accounts.login.chinese' | translate }}
        </div>
        <hr />
        <div
            type="button"
            href="#"
            (click)="onLanguageClick('my')"
            class="w-100 language-text d-flex flex-column align-items-center"
        >
            <img
                src="../../assets/images/header/lang-my.png"
                alt="my"
                class="language-img"
            />
            {{ 'accounts.login.malay' | translate }}
        </div>
    </div>
    <div class="d-flex align-item-center mt-3 d-none">
        <div
            type="button"
            href="#"
            (click)="onLanguageClick('th')"
            class="w-100 language-text d-flex flex-column align-items-center"
        >
            <img
                src="../../assets/images/header/lang-th.png"
                alt="th"
                class="language-img"
            />
            {{ 'accounts.login.thai' | translate }}
        </div>
        <div
            type="button"
            href="#"
            (click)="onLanguageClick('vn')"
            class="w-100 language-text d-flex flex-column align-items-center"
        >
            <img
                src="../../assets/images/header/lang-vn.png"
                alt="vn"
                class="language-img"
            />
            {{ 'accounts.login.viet' | translate }}
        </div>
        <div
            type="button"
            href="#"
            (click)="onLanguageClick('id')"
            class="w-100 language-text d-flex flex-column align-items-center"
        >
            <img
                src="../../assets/images/header/lang-id.png"
                alt="id"
                class="language-img"
            />
            {{ 'accounts.login.indon' | translate }}
        </div>
    </div>
</p-dialog> -->
