<div class="footer">
  <ul class="footer-nav">
    <li class="item">

      <div class="partner-list">
        <!-- <a href="javascript:void(0)"><img src="../../assets/images/footer/game1.png" alt=""></a> -->
        <a href="javascript:void(0)"><img src="../../assets/images/footer/bggaming.png" alt=""></a>
        <a href="javascript:void(0)"><img src="../../assets/images/footer/ct855.png" alt=""></a>
        <a href="javascript:void(0)" style="width: 80px;"><img src="../../assets/images/footer/16win.png" alt="" style="padding-top: 15px; padding-bottom: 18px;"></a>
        <!-- <a href="javascript:void(0)"><img src="../../assets/images/footer/game3.png" alt=""></a>
        <a href="javascript:void(0)"><img src="../../assets/images/footer/game4.png" alt=""></a>
        <a href="javascript:void(0)"><img src="../../assets/images/footer/game5.png" alt=""></a>
        <a href="javascript:void(0)"><img src="../../assets/images/footer/game6.png" alt=""></a>
        <a href="javascript:void(0)"><img src="../../assets/images/footer/game7.png" alt=""></a>
        <a href="javascript:void(0)"><img src="../../assets/images/footer/game8.png" alt=""></a>
        <a href="javascript:void(0)"><img src="../../assets/images/footer/game9.png" alt=""></a>
        <a href="javascript:void(0)"><img src="../../assets/images/footer/game10.png" alt=""></a> -->
      </div>
    </li>
    <!-- <li class="item">

      <div class="connect-box">
        <div class="connect-list">
          <div class="connect-top">
            <em href="javascript:;" class="i18n" data-tag="contactUs">Pagcor</em>
          </div>
          <div class="connect-bom">
            <a href="javascript:void(0)"><img src="../../assets/images/footer/Pagcor.png" alt="" style="height: 40px;"></a>
          </div>
        </div>
        <div class="connect-list">
          <div class="connect-top">
            <em href="javascript:;" class="i18n" data-tag="paymentCooperation">Payment Cooperation</em>
          </div>
          <div class="connect-bom">
            <a href="javascript:void(0)"><img src="../../assets/images/footer/icon-81pay.png" alt=""></a>
            <a href="javascript:void(0)"><img src="../../assets/images/footer/icon-alipay.png" alt=""></a>
            <a href="javascript:void(0)"><img src="../../assets/images/footer/icon-wing.png" alt=""></a>
            <a href="javascript:void(0)"><img src="../../assets/images/footer/icon-wechat.png" alt=""></a>
            <a href="javascript:void(0)"><img src="../../assets/images/footer/icon-aba.png" alt=""></a>
          </div>
        </div>
      </div>
    </li> -->
    <div class="copyright">
      <div class="copyright-help">
        <a href="javascript:void(0)" class="i18n" data-tag="aboutUs">about us</a>
        <a href="javascript:void(0)" class="i18n" data-tag="PrivacyPolicy">Privacy Policy</a>
        <a href="javascript:void(0)" class="i18n" data-tag="ResponsibleGambling">Responsible Gambling</a>
      </div>
      <div class="copyright-label">COPYRIGHT © BIG855 RESERVED</div>
    </div>
  </ul>


</div>