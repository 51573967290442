export const environment = {
    production: false,
    IdleTimeOut: 7200000,
    TokenKeys: {
        AccessToken: 'access_token',
        RefreshToken: "refresh_token",
        Profile: "profile",
        RememberMeToken_UN: 'remember_me_token_un',
        RememberMeToken_PSD: 'remember_me_token_psd',
        ThemeModeToken: 'theme_mode_token',
        LanguageToken: 'language_token',
        UsernameToken: 'user_name_token',
        SelectedGameType: 'selected_game_type',
    },
    HttpConfig: {
        EndPoint: 'https://devbig855webservicememberapp.azurewebsites.net',
        Timeout: 10000 //10sec
    },
    AdminAPIURL: 'https://devbig855webbackofficeadminsite.azurewebsites.net',
};
