import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

export enum NotificationSeverity {
    Info,
    Warn,
    Success,
    Error
}

@Injectable({
    providedIn: 'root'
})
export class NotifyService {
    constructor(private messageService: MessageService) {}

    public displayNotification(
        title: string,
        message: string,
        severity: NotificationSeverity
    ): void {
        switch (severity) {
            case NotificationSeverity.Info:
                this.displayInfo(title, message);
                break;
            case NotificationSeverity.Error:
                this.displayError(title, message);
                break;
            case NotificationSeverity.Warn:
                this.displayWarning(title, message);
                break;
            case NotificationSeverity.Success:
                this.displaySuccess(title, message);
                break;
            default:
                this.displayInfo(title, message);
                break;
        }
    }

    private displayInfo(title: string, message: string): void {
        this.messageService.add({
            severity: 'info',
            summary: title,
            detail: message
        });
    }

    private displayError(title: string, message: string): void {
        this.messageService.add({
            severity: 'error',
            summary: title,
            detail: message
        });
    }

    private displayWarning(title: string, message: string): void {
        this.messageService.add({
            severity: 'warn',
            summary: title,
            detail: message
        });
    }

    private displaySuccess(title: string, message: string): void {
        this.messageService.add({
            severity: 'success',
            summary: title,
            detail: message
        });
    }
}
