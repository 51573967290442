<div class="col-12">
    <div class="content-header">
        <cm-header></cm-header>
    </div>
    <div class="content-wrapper">
        <section id="home" class="screen-overflow">
            <div>
                <router-outlet></router-outlet>
            </div>

            <p-dialog class="non-header" [(visible)]="auth.displayLogin"
                [dismissableMask]="true" [draggable]="false" [resizable]="false" [style]="{width: '400px'}">
                    <div class="align-item-center">
                        <cm-login></cm-login>            
                    </div>
            </p-dialog>
        </section>
    </div>
    <div class="content-footer">
        <cm-footer [style]="{width: '100%'}"></cm-footer>
    </div>
</div>