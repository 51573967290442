import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService, ThemeService } from '../services';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'cm-contents',
  templateUrl: './contents.component.html',
  styleUrls: ['./contents.component.css']
})
export class ContentsComponent implements OnInit, AfterViewInit, OnDestroy {
  private theme$: Subscription | null;
  
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private theme: ThemeService,
    protected auth: AuthService
  ) {
    this.theme$ = null;
  }

  ngOnInit(): void {
    this.subscribeToThemeChange();
  }

  ngAfterViewInit(): void {
    let currentTheme: string = this.theme.getTheme();
    this.theme.initTheme(currentTheme, this.document);
  }

  ngOnDestroy(): void {
    this.theme$?.unsubscribe();
  }

  private subscribeToThemeChange(): void {
    this.theme$ = this.theme._thememode$.subscribe(theme => {
      this.theme.initTheme(theme, this.document);
    });
  }
}
