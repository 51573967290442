import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Balance } from 'src/app/models/credit.interface';
import { environment as env } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { TokenService } from '../token/token.service';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class CreditService {
  private creditBalanceSubject = new BehaviorSubject<number>(0);
  creditBalance$ = this.creditBalanceSubject.asObservable();

  constructor(
    private http: HttpService,
    private token: TokenService,
    private auth: AuthService,
  ) {

  }
  setCreditBalance(balance: number) {
    this.creditBalanceSubject.next(balance);
  }

  getCreditBalance(): number {
    return this.creditBalanceSubject.value;
  }

  refreshBalance() {

    if(!this.auth.isAuthenticated){
      return;
    }
    // const userProfile = this.auth.getUserProfile();

    const payload: any = {
      CurrentCulture: "",
      AcctUserId: "",
      UserRoleId: 0,
      LoggedInAcctUserId: "",
      // IpAddress: "",
      UserAgent: "",
      Country: "",
      // AcctId: userProfile?.AcctId,
      Currency: "MYR"
    }

    this.http.post('credit', 'getbalance', payload)
      .subscribe((resp: Balance) => {
        if (resp && resp.Code == 1) {
          this.setCreditBalance(resp.Balance);
        }
      });
    
  }
}
