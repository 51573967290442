import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { ContentsComponent } from './contents/contents.component';


const routes: Routes = [
  { path: '', redirectTo: 'contents', pathMatch: 'full' },
  {
    path: 'contents',
    loadChildren: () => import('./contents/contents.module').then(m => m.ContentsModule)
  },
  { path: '**', component: ContentsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), TranslateModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
