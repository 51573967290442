<div class="col text-white">
    <div class="ms-2 me-2">
        <i class="fa-solid fa-volume-high"></i>
    </div>
    <!-- <i class="iconfont icon-weibiaoti"></i> -->
    <marquee *ngIf="selectedLanguage === 'en-US'">
        <span *ngFor="let msg of runningMessages">
            {{ msg.Message }}
        </span>
    </marquee>
    <marquee *ngIf="selectedLanguage === 'zh-CN'">
        <span *ngFor="let msg of runningMessages">
            {{ msg.MessageCn ? msg.MessageCn : msg.Message }}
        </span>
    </marquee>
    <!-- <marquee *ngIf="selectedLanguage === 'my'">
        <span *ngFor="let msg of runningMessages">
            {{ msg.MessageMy ? msg.MessageMy : msg.Message }}
        </span>
    </marquee> -->
    <marquee *ngIf="selectedLanguage === 'th-TH'">
        <span *ngFor="let msg of runningMessages">
            {{ msg.MessageTh ? msg.MessageTh : msg.Message }}
        </span>
    </marquee>
    <marquee *ngIf="selectedLanguage === 'vi-VN'">
        <span *ngFor="let msg of runningMessages">
            {{ msg.MessageVn ? msg.MessageVn : msg.Message }}
        </span>
    </marquee>
    <marquee *ngIf="selectedLanguage === 'id-ID'">
        <span *ngFor="let msg of runningMessages">
            {{ msg.MessageId ? msg.MessageId : msg.Message }}
        </span>
    </marquee>
    <marquee *ngIf="selectedLanguage === 'jp-JP'">
        <span *ngFor="let msg of runningMessages">
            {{ msg.MessageJp ? msg.MessageJp : msg.Message }}
        </span>
    </marquee>
    <marquee *ngIf="selectedLanguage === 'ko-KO'">
        <span *ngFor="let msg of runningMessages">
            {{ msg.MessageKo ? msg.MessageKo : msg.Message }}
        </span>
    </marquee>
    <marquee *ngIf="selectedLanguage === 'tw-TW'">
        <span *ngFor="let msg of runningMessages">
            {{ msg.MessageTw ? msg.MessageTw : msg.Message }}
        </span>
    </marquee>
</div>
