import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';

import { HttpService } from 'src/app/services';
import { Discover, UserInfo } from 'src/app/models';
import { Subject } from 'rxjs/internal/Subject';


@Injectable({
  providedIn: 'root'
})
export class LandingPageService {
  public userInfoSubject$: Subject<UserInfo | null> = new Subject<UserInfo | null>();

  constructor(private http: HttpService) {
  }

  public getDiscover(): Observable<Discover> {
    return this.http.get('mapidiscover', 'discover', [{ key: "Platform", value: "Web" }])
      .pipe(map((m: Discover) => {
        if (m) {
          const userInfo: UserInfo = { Username: m.Username, Currency: m.Currency, Cash: m.Cash, Wallets: m.Wallets };
          this.userInfoSubject$.next(userInfo);
        }
        else {
          this.userInfoSubject$.next(null);
        }
        return m;
      }));
  }

  public quitGame(): Observable<any> {
    return this.http.get('mapidiscover', 'quitgame', [{ key: "Platform", value: "Web" }]);
  }
}
