import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs/internal/Subscription';
import randomColor from 'randomcolor';

import {
  AuthService,
  NotificationSeverity,
  NotifyService,
  ThemeService,
  TokenService,
  CreditService
} from 'src/app/services';

import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'cm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  protected frmAuth: FormGroup;
  protected selectedLanguage: string | null;
  protected currentTheme: string;
  private theme$: Subscription | null;
  private language$: Subscription | null;
  protected base64Image: string | null = null;
  protected generatedCaptcha: string;

  @ViewChild('codeCanvas') codeCanvas!: ElementRef;

  constructor(
    private fb: FormBuilder,
    protected auth: AuthService,
    private token: TokenService,
    private router: Router,
    private translate: TranslateService,
    private spinner: NgxSpinnerService,
    private notification: NotifyService,
    private theme: ThemeService,
    private credit: CreditService
  ) {
    this.selectedLanguage = '';
    this.currentTheme = this.theme.getTheme();
    this.theme$ = null;
    this.language$ = null;
    this.base64Image = '';
    this.generatedCaptcha = '';

    if (env.production) {
      this.frmAuth = this.fb.group({
        username: new FormControl('', Validators.required),
        password: new FormControl('', Validators.required),
        captcha: new FormControl('', Validators.required),
        rememberMe: new FormControl(false)
      });
    } else {
      this.frmAuth = this.fb.group({
        username: new FormControl('', Validators.required),
        password: new FormControl('', Validators.required),
        captcha: new FormControl('', Validators.required),
        rememberMe: new FormControl(false)
      });
    }
  }

  ngOnInit(): void {
    this.selectedLanguage = this.token.getLocalToken(
      env.TokenKeys.LanguageToken
    );
    console.log(this.selectedLanguage);

    this.language$ = this.translate.onLangChange.subscribe((value: any) => {
      this.selectedLanguage = value.lang;
    });

    if (this.token.getCookieToken(env.TokenKeys.RememberMeToken_UN)) {
      const _username: string = this.token.getCookieToken(
        env.TokenKeys.RememberMeToken_UN
      )!;
      this.frmAuth.controls['username'].setValue(_username);
      this.frmAuth.controls['rememberMe'].setValue(true);
    }

    if (this.token.getCookieToken(env.TokenKeys.RememberMeToken_PSD)) {
      const _password: string = this.token.getCookieToken(
        env.TokenKeys.RememberMeToken_PSD
      )!;
      this.frmAuth.controls['password'].setValue(_password);
    }

    this.subscribeToThemeChange();
  }

  ngOnDestroy(): void {
    this.theme$?.unsubscribe();
    this.language$?.unsubscribe();
  }

  private subscribeToThemeChange(): void {
    this.theme$ = this.theme._thememode$.subscribe((theme) => {
      this.currentTheme = theme;
    });
  }

  protected enterLogin(type: number) {
    const isValid = this.loginValidation(type);
    if (!isValid || !this.frmAuth.valid) {
      this.username!.markAllAsTouched();
      this.password!.markAllAsTouched();
      this.captcha!.markAllAsTouched();
      return;
    }

    this.token.removeCookieToken(env.TokenKeys.RememberMeToken_UN);
    this.token.removeCookieToken(env.TokenKeys.RememberMeToken_PSD);

    this.auth
      .login(this.username!.value, this.password!.value, this.captcha!.value)
      .subscribe(
        (isSuccess: boolean) => {
          if (isSuccess) {
            this.auth.toggleLoginDisplay(false);
            if (this.rememberMe!.value) {
              this.token.setCookieToken(
                env.TokenKeys.RememberMeToken_UN,
                this.username!.value
              );
              this.token.setCookieToken(
                env.TokenKeys.RememberMeToken_PSD,
                this.password!.value
              );
            }
            this.credit.refreshBalance();
            this.router.navigate(['/']);
          }
        },
        (err: HttpErrorResponse) => {
          let title: string = err.statusText;
          let message: string = err.message;

          this.notification.displayNotification(
            title,
            message,
            NotificationSeverity.Error
          );
        }
      );
  }

  private loginValidation(type: number): boolean {
    let username, password, captcha;
    var title = 'Login',
      message = '';
    // if (getSession('isFree')) {
    //     $('.login-group').show();
    //     hideFreeGame();
    // } else {
    if (type === 1) {
      [username, password, captcha] = [
        this.username!.value,
        this.password!.value,
        this.captcha!.value
      ];
    }
    // else {
    //     [username, password, codenumber] = [
    //         getusername1(),
    //         getpassword1(),
    //         getcodenumber1()
    //     ];
    // }

    if (!username) {
      this.translate
        .get('accounts.login_error.invalid_username')
        .subscribe((text: string) => {
          message = text;
        });
      this.notification.displayNotification(
        title,
        message,
        NotificationSeverity.Error
      );
      return false;
    }
    if (!password) {
      this.translate
        .get('accounts.login_error.invalid_password')
        .subscribe((text: string) => {
          message = text;
        });
      this.notification.displayNotification(
        title,
        message,
        NotificationSeverity.Error
      );
      return false;
    }
    if (password.length < 6 || password.length > 14) {
      this.translate
        .get('accounts.login_error.invalid_password_length')
        .subscribe((text: string) => {
          message = text;
        });
      this.notification.displayNotification(
        title,
        message,
        NotificationSeverity.Error
      );
      return false;
    }
    if (!captcha) {
      this.translate
        .get('accounts.login_error.invalid_captcha')
        .subscribe((text: string) => {
          message = text;
        });
      this.notification.displayNotification(
        title,
        message,
        NotificationSeverity.Error
      );
      return false;
    }
    if (captcha && captcha != this.generatedCaptcha) {
      this.translate
        .get('accounts.login_error.captcha_does_not_match')
        .subscribe((text: string) => {
          message = text;
        });
      this.notification.displayNotification(
        title,
        message,
        NotificationSeverity.Error
      );
      return false;
    }
    // }
    return true;
  }

  protected getCodeImg(type: number) {
    // Generate a random 4-digit code
    this.generatedCaptcha = Math.floor(1000 + Math.random() * 9000).toString();

    const canvas: HTMLCanvasElement = this.codeCanvas.nativeElement;
    const ctx = canvas.getContext('2d');

    if (ctx) {
      // Set canvas size
      canvas.width = 70;
      canvas.height = 45;

      // Set white background
      ctx.fillStyle = '#FFFFFF';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Generate random colors for each digit
      const colors = Array.from({ length: 4 }, () => randomColor());

      // Draw each digit with its own color
      ctx.font = 'bold 26px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';

      for (let i = 0; i < this.generatedCaptcha.length; i++) {
        ctx.fillStyle = colors[i];
        ctx.fillText(this.generatedCaptcha[i], (canvas.width / 5) * (i + 1), canvas.height / 2);
      }

      // Convert the canvas to a base64-encoded JPEG image
      this.base64Image = canvas.toDataURL('image/jpeg');
    } else {
      console.error('2D context not supported or canvas already initialized');
    }
  }

  get username() {
    return this.frmAuth.get('username');
  }

  get password() {
    return this.frmAuth.get('password');
  }

  get rememberMe() {
    return this.frmAuth.get('rememberMe');
  }

  get captcha() {
    return this.frmAuth.get('captcha');
  }
}
