import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { AuthService, TokenService } from './services';

import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  idleTime = env.IdleTimeOut;
  idleTimer: any = null;
  language: any = null;

  @HostListener('window:keydown')
  @HostListener('document:scroll', ['$event'])
  @HostListener('window:mousemove')
  resetTimer() {
    this.timeoutAction();
  }
  constructor(
    protected translate: TranslateService,
    private token: TokenService,
    private auth: AuthService,
    private router: Router
  ) {
    this.resetTimer();

    if (token.getLocalToken(env.TokenKeys.LanguageToken) != null) {
      this.language = token.getLocalToken(env.TokenKeys.LanguageToken);
      translate.setDefaultLang(this.language);
    } else {
      token.setLocalToken(env.TokenKeys.LanguageToken, 'en-US');
      this.language = translate.getBrowserCultureLang();
      if (this.language) {
        translate.setDefaultLang(this.language);
      } else {
        translate.setDefaultLang('en-US');
      }
    }
  }

  ngOnInit() {
    const language = this.token.getLocalToken(env.TokenKeys.LanguageToken);
      this.translate.use(language ?? 'en-US');
  }

  private logout(): void {
    this.auth.logout().subscribe((resp) => {
      if (resp) {
        this.router.navigate(['']);
      }
    });
  }

  private timeoutAction(): void {
    if (this.auth.isAuthenticated()) {
      clearTimeout(this.idleTimer);
      this.idleTimer = setTimeout(() => {
        this.logout();
      }, this.idleTime);
    }
    // This only run the logic while user is login and let the timer clear after logout
    this.auth.getUserLoggedIn().subscribe((userLoggedIn) => {
      if (userLoggedIn) {
        this.resetTimer();
      } else {
        clearTimeout(this.idleTimer);
      }
    });
  }
}
